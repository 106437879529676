import React, { useEffect } from "react";
import { GetServerSideProps, NextPage } from "next";
import { Header } from "components/front/layout/Header";
import GeneralHead from "components/front/head/GeneralHead";
import { GeneralFoot } from "components/front/layout/GeneralFoot";
import CookieHandler from "lib/cookie";
import { errorToast } from "lib/toast";
import { useFrontAnonymous } from "lib/hooks/useFrontAnonymous";
import styled from "styled-components";
import Main from "components/front/layout/Main";
import dynamic from "next/dynamic";
import { Props as LoginSectionProps } from "components/front/login/LoginSection";
import { Props as RegisterSectionProps } from "components/front/login/RegisterSection";
import { Props as FooterProps } from "components/front/layout/Footer";
import RootSection from "components/front/layout/RootSection";

const RegisterSection = dynamic<RegisterSectionProps>(() => import("components/front/login/RegisterSection"));
const LoginSection = dynamic<LoginSectionProps>(() => import("components/front/login/LoginSection"));
const Footer = dynamic<FooterProps>(() => import("components/front/layout/Footer"));

type Props = {
  notice: string;
};
const LoginPage: NextPage<Props> = (props: Props) => {
  useFrontAnonymous();
  useEffect(() => {
    if (props.notice) {
      errorToast(props.notice);
    }
  }, []);
  return (
    <>
      <GeneralHead screenName="ログイン" />
      <Header />
      <Main>
        <StyledRootSection $pcWidth="800px">
          <RegisterSection />
          <LoginSection />
        </StyledRootSection>
      </Main>
      <Footer />
      <GeneralFoot />
    </>
  );
};

export const getServerSideProps: GetServerSideProps<Props> = async (context) => {
  const cookieHandler = new CookieHandler(context);
  const notice = cookieHandler.oneTimeNotice();
  return { props: { notice } };
};

export default LoginPage;

const StyledRootSection = styled(RootSection)`
  @media screen and (min-width: 961px) {
    display: flex;
    justify-content: space-between;
  }
  @media screen and (max-width: 960px) {
    & > *:not(:first-child) {
      margin-top: 40px;
    }
  }
`;
