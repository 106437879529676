import Head from "next/head";
import React, { FC } from "react";
import { TITLE } from "components/front/head/constants";
import ResetCss from "components/front/layout/ResetCss";

type Props = {
  screenName: string;
};
const GeneralHead: FC<Props> = (props: Props) => {
  const title = props.screenName ? `${props.screenName} | ${TITLE}` : TITLE;
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width,initial-scale=1,user-scalable=no" />
        <title key="title">{title}</title>
        <meta name="robots" content="noindex" key="robots" />
      </Head>
      <ResetCss />
    </>
  );
};
export default GeneralHead;
