import { useEffect } from "react";
import { useRouter } from "next/router";
import { FrontMeService } from "../service/client/FrontMeService";
import { useFrontAuthContext } from "lib/hoc/withFrontAuth";

/**
 * ログイン済みである場合にトップ画面へリダイレクトするhookです。
 * 未ログイン状態であることを前提とした画面で定義します。
 */
export function useFrontAnonymous(): void {
  const router = useRouter();
  const member = useFrontAuthContext();
  useEffect(() => {
    if (!!member) {
      router
        .replace(router.query.original ? (router.query.original as string) : (process.env.LANDING_POINT as string))
        .then(() => window.scrollTo(0, 0))
        .catch(console.error);
    }
  }, [member]);
}
